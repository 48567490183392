import axios from '../config/api.config'

class AgencyService {
  getAll() {
    return axios.get('/agencies')
    // return axios.get('/organizations/clients')
  }
  getUsers(id) {
    return axios.get(`/users/agency/${id}`)
  }

  get(id) {
    return axios.get(`/agencies/${id}`)
  }

  // getWithUsers(id) {
  //   return axios.get(`/organizations/${id}/users`)
  // }

  // create(data) {
  //   return axios.post('/organizations', data)
  // }

  // update(id, data) {
  //   return axios.put(`/organizations/${id}`, data)
  // }

  // delete(id) {
  //   return axios.delete(`/organizations/${id}`)
  // }

  // deleteAll() {
  //   return axios.delete(`/organizations`)
  // }

  // findByTitle(name) {
  //   return axios.get(`/organizations?name=${name}`)
  // }
}

export default new AgencyService()
