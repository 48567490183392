<template>
  <div class="login">
    <login />
  </div>
</template>
<script>
import Login from '@/components/Login'
export default {
  components: {
    Login
  }
}
</script>
