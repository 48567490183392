<template>
  <div>
    <v-container>
      <snackbar :attr="snackbar" />
      <v-card class="mx-auto" max-width="500">
        <v-img height="200" src="../assets/women-reentry.png"></v-img>
        <!-- <v-toolbar flat class="pt-4">
          <v-spacer></v-spacer>
          <v-toolbar-title
            ><vue-fontawesome icon="lock" size="2x"
          /></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom> </v-tooltip>
        </v-toolbar> -->
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="login.email"
              :rules="emailRules"
              label="Email..."
              name="email"
              autocomplete="email"
              type="text"
              required
              ><vue-fontawesome
                icon="user"
                color="grey"
                slot="prepend"
                class="mt-1"
            /></v-text-field>

            <v-text-field
              v-model="login.password"
              v-on:keyup.enter="loginUser"
              :rules="passwordRules"
              id="password"
              label="Password..."
              name="password"
              type="password"
              autocomplete="current-password"
              required
              ><vue-fontawesome
                icon="lock"
                color="grey"
                slot="prepend"
                class="mt-1"
            /></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetPasswordPanel = true"
            >Reset/Forgot password</v-btn
          >
          <v-btn color="warning" elevation="0" @click="reset">Reset</v-btn>
          <!-- <v-btn
                elevation="0"
                dark
                class="primary-btn"
                @click="loginUser"
                :disabled="!valid"
                >Login</v-btn
              > -->
          <v-btn
            elevation="0"
            color="#29526b"
            class="ma-2 white--text"
            @click="loginUser"
            :disabled="!valid"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-expand-transition>
        <div v-show="resetPasswordPanel">
          <v-card class="mx-auto mt-6" max-width="500">
            <v-card-text>
              <v-form ref="forgot" v-model="forgotValid">
                <v-text-field
                  v-model="resetEmail"
                  :rules="emailRules"
                  label="Email..."
                  name="email"
                  autocomplete="email"
                  type="text"
                  required
                  ><vue-fontawesome
                    icon="user"
                    color="grey"
                    slot="prepend"
                    class="mt-1"
                /></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                color="#29526b"
                class="ma-2 white--text"
                @click="requestReset"
                :disabled="!forgotValid"
                >Send</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-expand-transition>
    </v-container>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import AgencyService from '@/services/AgencyService'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Login',
  components: { Snackbar },
  data: () => ({
    snackbar: {
      model: false,
      color: '',
      message: '',
      icon: ['fas', 'exclamation-triangle'],
      timeout: 3000
    },
    resetPasswordPanel: false,
    hasError: false,
    // loggedIn: '333',
    valid: false,
    forgotValid: false,
    login: {
      email: '',
      // email: 'michael.layne@hey.com',
      password: ''
    },
    resetEmail: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    // password: 'alix2020!',
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length > 5 || 'Name must be more than 4 characters'
    ]
  }),
  methods: {
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    resetPassword() {
      this.forgotValid = false
      this.$refs.form.reset()
    },
    resetForgotValidation() {
      this.$refs.forgot.resetValidation()
    },
    validateForgotForm() {
      this.$refs.forgot.validate()
    },
    async loginUser() {
      if (this.valid) {
        try {
          const loginResult = await UserService.login(this.login)
          // console.log(loginResult)
          // let response = await this.$http.post('/user/login', this.login)
          const token = loginResult.data.token
          const user = loginResult.data.user
          // console.log(token)
          if (token) {
            localStorage.setItem('token', token)
            const agency = {
              id: loginResult.data.user.agency._id,
              name: loginResult.data.user.agency.name,
              abbr: loginResult.data.user.agency.abbr
            }
            // console.log(user)
            // get agencies
            const agenciesResult = await AgencyService.getAll()
            if (!agenciesResult) {
              return
            }
            // console.log(agenciesResult)
            // const agencies = agenciesResult.data
            user.agency = agency
            user.agencies = agenciesResult.data
            await this.$store.dispatch('login', { user })
            this.$router.push('/citizens')
          }
          // now set need to set the agency
          // console.log(user)
        } catch (err) {
          this.snackbar.model = true
          this.snackbar.message = err.response.data.message
          // this.snackbar.message = 'Wrong username or password!'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          // swal('Error', 'Something Went Wrong', 'error')
          // console.log(err.response.data)
        }
      }
    },
    async requestReset() {
      if (this.forgotValid) {
        try {
          const data = {
            email: this.resetEmail
          }
          const requestResult = await UserService.requestReset(data)
          if (!requestResult) {
            return
          }
          this.snackbar.model = true
          this.snackbar.message = 'Email found. Please check your email.'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log(requestResult)
        } catch (err) {
          this.snackbar.model = true
          this.snackbar.message = err.response.data.msg
          // this.snackbar.message = 'Email not found.'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          // swal('Error', 'Something Went Wrong', 'error')
          // console.log(err.response.data)
        }
      }
      this.resetPasswordPanel = false
    }
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  font-weight: 500;
  letter-spacing: 0;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: none;
}
</style>
