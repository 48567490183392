import axios from '../config/api.config'

class UserService {
  getAll() {
    return axios.get('/users')
  }

  get(id) {
    return axios.get(`/users/${id}`)
  }

  login(data) {
    return axios.post('/auth/login', data)
  }

  requestReset(data) {
    return axios.post('/auth/request-reset', data)
  }

  checkToken(id) {
    return axios.get(`/auth/reset-password/${id}`)
  }

  resetPassword(data) {
    return axios.post('/auth/reset-password', data)
  }

  logout() {
    return axios.get('/auth/logout')
  }

  create(data) {
    return axios.post('/users', data)
  }

  update(id, data) {
    return axios.put(`/users/${id}`, data)
  }

  updateStatus(id, data) {
    return axios.put(`/users/status/${id}`, data)
  }

  delete(id) {
    return axios.delete(`/users/${id}`)
  }

  deleteAll() {
    return axios.delete(`/users`)
  }

  findByTitle(name) {
    return axios.get(`/users?name=${name}`)
  }
}

export default new UserService()
